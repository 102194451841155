@import '../../styles/mixins';

@mixin reset-button {
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  border-radius: var(--base-border-radius--large);
  border-color: transparent;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: none;
  transition: box-shadow 0.2s, border 0.2s;
}

.radio-button {
  @include reset-button;
  color: #333;

  border-radius: var(--base-border-radius--large);
  -webkit-appearance: none;

  &.checked {
    border: var(--base-border--l) solid var(--color-primary);
    background-color: rgba(142, 0, 56, 0.08);
  }

  &:not(.checked):hover {
    border: var(--base-border--l) solid transparent;
    box-shadow: var(--base-box-shadow--medium);

    .ee_radio__label:before {
      border: var(--base-border--l) solid var(--color-primary);
      background-color: var(--color-primary-bg);
      box-shadow: 0 0 0 var(--base-spacer--s) var(--color-primary-bg);
    }
  }

  &--column {
    box-sizing: border-box;
    padding: var(--base-spacer--l) 0 var(--base-spacer--l);
    border: var(--base-border) solid var(--color-dark-gray);
    border-radius: var(--base-border-radius--large);
    text-align: center;
    -webkit-appearance: none;

    &__text {
      margin: var(--base-spacer--s) 0;
      font-weight: var(--base__heading__font-weight-semibold);
      font-size: var(--base-font-size--large-screen);
      line-height: var(--base-line-height);
      -webkit-appearance: none;
    }
  }

  &__subtitle {
    font-size: var(--base-font-size--medium-screen);
  }
  &--inline {
    display: flex;
    align-items: center;
    border: var(--base-border--l) solid var(--color-dark-gray);
    border-radius: var(--base-border-radius--large);
    text-align: left;
    max-width: 312px;
    display: block;

    @include responsiveMixin('breakpoint-m') {
      max-width: calc(var(--base-spacer) * 26.25);
    }

    &__wrapper {
      display: flex;
      padding: 0 1.25rem;
      @include responsiveMixin('breakpoint-m') {
        padding: var(--base-spacer--xs) var(--base-spacer);
      }
    }

    &__wrapper_subtitle {
      display: flex;
      padding: 0.5rem 1.5rem 1rem 1rem;
    }

    &__text {
      margin: 1rem 0 1rem;
      font-weight: var(--base__heading__font-weight-semibold);
    }
    &__text_subtitle {
      margin: 1rem 0;
      font-weight: var(--base__heading__font-weight-semibold);
      font-size: var(--base-spacer);
    }
  }
}
