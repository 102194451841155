@use './resets';
@use './variables';
@use './base/index';
@use './mixins';

.error-text {
  color: var(--color-error);
}

.divider {
  border: none;
  height: 1px;
  background-color: var(--color-light-gray);
}
