@import '../../../../styles/mixins';

.personal-data-intro {
  @include responsiveMixin('breakpoint-m') {
    margin-top: var(--base-spacer--3xl);
  }
  &__text {
    text-align: center;
    margin-bottom: 0;
    line-height: var(--base-line-height--s);

    @include responsiveMixin('breakpoint-m') {
      width: 100%;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .ee_tooltip-icon {
      padding: 0 0;
    }
    .ee_tooltip-icon__icon {
      color: black !important;
    }
    &-text {
      margin-right: var(--base-spacer--s);
      font-size: var(--base-font-size--medium-screen);
      font-weight: var(--base__heading__font-weight-semibold);
    }
  }
}
