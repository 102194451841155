@import '../../styles/mixins';
@import '../../styles/variables';

$box-shadow: var(--base-box-shadow--medium);

[data-theme='ergo-one'] {
  .app-body-spacing {
    .insurance-coverage {
      .ee_form-footer {
        margin: var(--base-spacer);
      }

      &--bikeRadios {
        flex-wrap: nowrap !important;

        & > .ee_form-row__descriptor {
          // This is mocking the width from previous field
          // which is built using grid instead of flex
          min-width: 234px;
        }

        & > .ee_form-row__container > .ee_form-row__item {
          display: block !important;
          flex: initial !important;
          width: 100% !important;
        }
      }

      &__options {
        .ee_radiotabs {
          padding: 0;

          &__tab {
            background-color: #f2f2f2;
            max-width: calc(var(--base-spacer--ms) * 22);
            width: 100%;
            flex-basis: auto;

            .ee_radiotabs__tab__checkmark {
              padding-bottom: 24px;
            }

            &--selected {
              background-color: #8e0038;
              box-shadow: $box-shadow;
            }

            &:hover:not(.ee_radiotabs__tab--selected):not(
                .ee_radiotabs__tab--disabled
              ) {
              background-color: #f2f2f2;
            }

            &__title {
              font-size: var(--base-font-size--large-screen);
            }
          }

          &__panellist {
            box-shadow: $box-shadow;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-top: var(--base-spacer--3xl);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @include responsiveMixin('breakpoint-m') {
              padding-top: var(--base-spacer);
            }
          }

          &__tablist {
            height: calc(var(--base-spacer--5xl) + var(--base-spacer--s));
            width: calc(
              var(--base-percentage) - var(--base-percentage--by-ten)
            );
            margin-left: auto;
            margin-right: auto;

            @include responsiveMixin('breakpoint-m') {
              height: calc(var(--base-spacer--5xl) + var(--base-spacer--m));
            }

            //specific selector to override internal EE styles
            .ee_radiotabs__tab {
              &[role='tab']:not(.ee_radiotabs__tab--selected):hover {
                color: #8e0038;
              }
              padding-top: 5px;
              &__checkmark::before {
                bottom: 25px;
              }
              &__checkmark::after {
                bottom: 25px;
              }
            }
          }

          @include responsiveMixin('breakpoint-m') {
            &__tab {
              &:first-child {
                border-radius: var(--base-border-radius--large) 0 0
                  var(--base-border-radius--large);
              }
              &:last-child {
                border-radius: 0 var(--base-border-radius--large)
                  var(--base-border-radius--large) 0;
              }
              &--selected {
                box-shadow: $box-shadow;
              }
            }
            &__panellist {
              padding-top: calc(var(--base-spacer) * 2.5);
            }
          }
        }

        .card-section {
          &__wrap {
            padding: var(--base-spacer);
            margin: 0 auto;

            @include responsiveMixin('breakpoint-m') {
              width: calc(
                var(--base-percentage--by-fifty) +
                  (calc(var(--base-percentage--by-ten)) * 2)
              );
              max-width: calc(
                var(--base-percentage--by-fifty) +
                  (calc(var(--base-percentage--by-ten)) * 2)
              );

              .checkbox-row-wrapper {
                .ee_tooltip-wrapper {
                  .ee_tooltip-icon {
                    &__button {
                      margin-right: var(--base-spacer);
                    }
                  }
                }
              }
            }
          }

          .ee_form-row {
            margin: 0;

            &:not(.insurance-coverage--bikeRadios) {
              @include responsiveMixin('breakpoint-m') {
                display: grid;
                grid-template: calc(var(--base-percentage) / 3) calc(
                    var(--base-spacer--ms) * 34
                  );
                grid-template-areas:
                  'a b'
                  'a c';
                max-width: calc(var(--base-spacer--ms) * 57);
                column-gap: calc(var(--base-spacer) * 3);
              }

              * {
                width: initial;
              }

              .ee_form-row {
                &__descriptor {
                  grid-area: a;
                  align-self: start;
                  margin-bottom: var(--base-spacer--ms);
                }

                &__container {
                  grid-area: b;
                }

                &__messages {
                  grid-area: c;
                }

                &__item {
                  margin: 0;

                  &-container {
                    .ee_select {
                      width: 100%;

                      select {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            &.insurance-coverage--bikeRadios {
              .ee_form-row {
                &__descriptor {
                  align-self: flex-start;
                  padding: 0;
                  min-width: initial !important;

                  @include responsiveMixin('breakpoint-m') {
                    width: calc(var(--base-spacer--ms) * 23.5);
                  }
                }

                &__container {
                  * {
                    width: initial;
                    margin-bottom: 0;
                  }
                }
              }
            }

            &__messages {
              padding: 0;
              margin: var(--base-spacer--ms) 0 0 0;
            }

            &__hint {
              padding: 0;
            }

            &:after {
              display: none;
            }
          }

          &__header {
            position: relative;
            border-bottom: none;
            background: transparent;
            box-shadow: none;
            text-align: center;

            &--with-icon {
              h3 {
                margin-top: 0;
              }
              svg {
                height: var(--base-icon-medium-width);
                width: var(--base-icon-medium-height);
              }
            }
          }

          &--with-border-bottom {
            border-bottom: var(--base-border) solid var(--color-lighter-gray);
          }

          &--ebike {
            border-left: var(--base-border) solid var(--color-light-gray);
            border-right: var(--base-border) solid var(--color-light-gray);
            border-bottom: var(--base-border) solid var(--color-light-gray);
            box-shadow: $box-shadow;
            border-bottom-left-radius: var(--base-border-radius--large);
            border-bottom-right-radius: var(--base-border-radius--large);
            background-color: #efe7ed;

            .card-section {
              &__wrap {
                .checkbox-wrapper {
                  column-gap: var(--base-spacer--s);

                  &-icons--unchecked {
                    .ee_checkbox--regular.ee_checkbox {
                      max-width: var(--base-spacer--l);
                    }
                  }
                }
              }

              &__header {
                z-index: map-get($zIndex, 'content') !important;
              }
            }
          }

          &:last-child {
            padding-bottom: var(--base-spacer);
          }
        }

        .list {
          list-style: none;
          padding: 0;

          &__element {
            @include responsiveMixin('breakpoint-m') {
              padding: var(--base-spacer--xs) 0 var(--base-spacer--m);
              border-bottom: 1px solid var(--color-lighter-gray);

              &:last-child {
                border-bottom: none;
              }
            }

            &__benefit {
              @include responsiveMixin('breakpoint-m') {
                border-bottom: 1px solid var(--color-lighter-gray);
              }
              &:last-child {
                border-bottom: none;
              }

              .checkbox-wrapper {
                column-gap: var(--base-spacer--s);

                &-icons--unchecked {
                  .ee_checkbox--regular.ee_checkbox {
                    max-width: var(--base-spacer--l);
                  }
                }
              }

              .checkbox-text-wrapper {
                &__title {
                  width: var(--base-percentage);
                }
              }

              .ee_tooltip-wrapper {
                .ee_tooltip-icon {
                  &__button {
                    padding: 0;
                    // width: var(--base-spacer--xl);
                  }
                }
              }

              &--none {
                display: none;
              }
            }

            @include responsiveMixin('breakpoint-m') {
              .information-wrapper {
                .ee_tooltip-wrapper {
                  .ee_tooltip-icon {
                    &__button {
                      margin-right: var(--base-spacer);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .information {
        &-wrapper {
          &__icon {
            @include responsiveMixin('breakpoint-m') {
              margin-right: var(--base-spacer--3xl);
            }
          }
        }
      }

      .insurance-coverage__payment {
        padding-top: var(--base-spacer--xl);
        max-width: var(--base-container);
        margin: 0 auto;
        text-align: center;
        width: calc(var(--base-percentage) - var(--base-percentage--by-five));

        @include responsiveMixin('breakpoint-m') {
          padding-top: calc(var(--base-spacer) * 2.5);
        }
      }

      .bike-options {
        margin: var(--base-spacer) auto;
        max-width: calc(var(--base-percentage--by-10) * 8.5);
        padding-left: calc(var(--base-spacer--m) * 3.725);

        &__title {
          font-size: var(--base-font-size--large-screen);
        }

        .ee_form {
          &-row {
            &.insurance-coverage--bikeRadios {
              display: grid;
              grid-template: var(--base-percentage);
              grid-template-areas:
                'a'
                'b'
                'c';

              @include responsiveMixin('breakpoint-m') {
                grid-template: calc(var(--base-percentage) / 3) calc(
                    var(--base-spacer--ms) * 34
                  );
                grid-template-areas:
                  'a b'
                  'a c';
                width: calc(var(--base-spacer--ms) * 57);
                gap: var(--base-spacer--xxl);
              }
            }

            &__descriptor {
              width: initial;
              grid-area: a;
            }

            &__container {
              grid-area: b;
            }

            &__messages {
              grid-area: c;
            }

            &__item {
              margin-right: 0;

              &-container {
                padding-right: 0;
              }
            }
          }
        }

        @include responsiveMixin('breakpoint-m') {
          padding: 0;
        }
      }

      .download-link {
        @include responsiveMixin('breakpoint-m') {
          margin-top: var(--base-spacer--l);
          .ee_svg-icon.ee_svg-icon--regular.ee_icon.ee_icon-downloadicon {
            vertical-align: middle;

            margin-right: var(--base-spacer--m);
            margin-bottom: var(--base-spacer--xs);
          }
        }
      }
    }
  }
}

.sum-insured-row {
  @include responsiveMixin('breakpoint-m') {
    border-bottom: 1px solid var(--color-lighter-gray);

    .ee_tooltip-icon {
      &__button {
        margin-right: var(--base-spacer) !important;
      }
    }
  }
}
