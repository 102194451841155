@import '../../styles/mixins';

.step-button {
  min-width: var(--base-button--width--medium);
  height: var(--base-button--height--medium);

  @include responsiveMixin('breakpoint-m') {
    min-width: var(--base-button--width--large);
    height: var(--base-spacer--3xxl);
  }

  &__wrapper {
    @include responsiveMixin('breakpoint-m') {
      padding: 0 var(--base-spacer--l);
    }
  }
}
