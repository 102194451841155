@import '../../../../styles/mixins';

.details-list {
  margin: var(--base-spacer--xl) 0;
  &__fields {
    display: flex;
    flex-direction: column;
    margin: var(--base-spacer--xl) 0;
    @include responsiveMixin('breakpoint-m') {
      flex-direction: row;
    }
    &-title {
      margin-bottom: var(--base-spacer--m);
      flex: 1;
      font-weight: bold;
      @include responsiveMixin('breakpoint-m') {
        margin: 0;
      }
    }
    &-description {
      @include responsiveMixin('breakpoint-m') {
        margin-left: var(--base-spacer--ml);
      }
      .description-element {
        margin: 0;
      }
      flex: 2;
    }
  }
  &__button {
    margin: var(--base-spacer--l) 0;
    display: flex;
    justify-content: center;
  }

  // overrides here to do not affect the whole layout
  .ee_button__content {
    align-self: center;
    display: flex;
    align-items: center;

    .ee_svg-icon {
      width: var(--base-font-size--large-screen-l) !important;
      height: var(--base-font-size--large-screen-l) !important;
      margin-right: var(--base-spacer--m);
    }
  }

  .ee_details__title {
    font-weight: bold;
    font-size: var(--base-font-size--large-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen-l);
    }
  }
}
