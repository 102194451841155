.Price {
  display: inline;

  span {
    display: inline-block;
  }

  &--with-space {
    span {
      display: inline;
    }
  }
}
