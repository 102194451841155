.drawer {
  display: flex;

  &__radio {
    &:first-child {
      margin-right: var(--base-spacer--3xxl);
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}
