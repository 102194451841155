@import '../../../../../styles/mixins';

.additional-question__content {
  .Autocomplete {
    @include responsiveMixin('breakpoint-m') {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      gap: var(--base-spacer);
    }

    .ee_autocomplete {
      @include responsiveMixin('breakpoint-m') {
        max-width: calc(var(--base-percentage--by-ten) * 4);
      }

      .ee_input-wrapper.ee_input {
        width: calc(var(--base-percentage) - var(--base-spacer--m) * 2);
      }
    }
  }

  .ee_form-row > .ee_form-row__messages {
    margin-top: var(--base-spacer--xs);
    margin-top: var(--base-spacer--m);
    margin-bottom: 0;
    margin-left: var(--base-spacer--xl);

    & > .ee_form-row__hint {
      padding-left: 0;
    }
  }

  .ee_date-input {
    margin-top: var(--base-spacer--xs);
    @include responsiveMixin('breakpoint-m') {
      margin-left: var(--base-spacer--xl);
    }
  }
}

.additional-question__subtitle-paragraph {
  margin-bottom: 0;
}
