.AemFragment {
  position: relative;
  height: 100%;
  width: 100%;

  &__loadingSpinner {
    left: 50%;
    margin-top: -14px;
    position: absolute;
    text-align: center;
    top: 50%;
  }

  &__iframe,
  &__injected {
    height: 100%;
    width: 100%;
  }

  &__iframe {
    border: none;
  }

  &__injected {
    box-sizing: border-box;
    padding: 32px 24px;
  }

  &__iframe {
    border: none;
    width: 100%;
    height: 100%;

    &__hidden {
      visibility: hidden;
    }
  }
}
