@import '../../styles/mixins';

[data-theme='ergo-one'] {
  .rate-calculator {
    background-color: var(--color-additional-5-lighter);
    padding: var(--base-spacer);

    &__title {
      text-align: center;
      margin: 0;
      font-size: var(--base-h6-font-size);
      line-height: var(--base-line-height--s);
    }

    .Price {
      .ee_price {
        display: inline-block;
        [data-part='value'],
        [data-part='currency'] {
          font-size: var(--base-font-size--large-screen-l);
          padding-left: var(--base-spacer--xs);
        }

        [data-part='suffix'] {
          font-size: var(--base-font-size--medium-screen);
        }

        @include responsiveMixin('breakpoint-m') {
          [data-part='value'],
          [data-part='currency'] {
            font-size: var(--base-line-height--l);
          }

          [data-part='suffix'] {
            font-size: var(--base-font-size--large-screen);
          }
        }
      }
    }
  }
}
