@import '../../styles/mixins';

.rate__wrapper {
  max-width: 850px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  font-size: var(--base-font-size--large-screen);
  line-height: var(--base-line-height--s);
  @include responsiveMixin('breakpoint-m') {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 0px;
    align-content: center;
    gap: 0;
  }
}

.rate__head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @include responsiveMixin('breakpoint-m') {
    align-items: flex-start;
    gap: 0;
  }
}

.rate__title {
  margin: 0;
  font-size: var(--base-font-size);
  line-height: var(--base-line-height--s);

  @include responsiveMixin('breakpoint-m') {
    font-size: var(--base-font-size--large-screen);
  }
}

.rate__subtitle {
  font-size: var(--base-font-size--small-screen);
  letter-spacing: 0.25px;

  &::first-letter {
    text-transform: uppercase;
  }

  @include responsiveMixin('breakpoint-m') {
    font-size: var(--base-font-size);
  }
}
