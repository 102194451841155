.home-location {
  &__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @media (min-width: 769px) {
      gap: 1.5rem;
    }
  }
}
