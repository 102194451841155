@import '../../styles/mixins';

.address {
  .ee_input-wrapper {
    margin: var(--base-spacer--l) 0;
  }

  .ee_modal {
    display: flex;
    align-items: center;
  }
}
