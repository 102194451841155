.homeSquareMeters {
  max-width: var(--base-spacer--6xl);
  margin: 0 auto;

  .ee_input {
    width: 75%;
    padding-right: var(--base-spacer--m) !important;
  }
  .ee_input-adornment--right {
    right: -15px;
  }
}
