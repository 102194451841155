@import '../styles/mixins';

.button-reset {
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  border-color: transparent;
  font-family: inherit;
  font-size: var(--base-font-size--small-screen);
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  @include responsiveMixin('breakpoint-m') {
    font-size: var(--base-font-size--medium-screen);
  }
}

.reset-input-artifacts {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
