@import '../../../../styles/mixins';

.additional-question__content {
  .textinput-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--base-spacer--xl);

    @include responsiveMixin('breakpoint-m') {
      &__business {
        column-gap: calc(
          var(--base-percentage--by-ten) + var(--base-percentage--by-one)
        );
      }

      &__policy {
        column-gap: var(--base-percentage--by-five);
      }

      &__input {
        width: var(--base-percentage);
      }
    }
  }
  .ee_form-row > .ee_form-row__messages {
    margin-top: var(--base-spacer--xs);
    margin-bottom: 0;
    margin-left: var(--base-spacer--xl);
  }
  .ee_date-input {
    @include responsiveMixin('breakpoint-m') {
      margin-left: var(--base-spacer--xl);
    }
  }
  .Autocomplete {
    &__label--col {
      max-width: none;
    }

    @include responsiveMixin('breakpoint-m') {
      .ee_autocomplete {
        max-width: var(--base-percentage) !important;
      }
    }
  }

  .select-row-container {
    @include responsiveMixin('breakpoint-m') {
      margin-bottom: var(--base-spacer--xs);
    }
  }
}
