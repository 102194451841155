@import '~@eg/elements/styles/breakpoints.scss';

// The !default suffix is making sure that the variable value is only
// assigned if the variable has not value yet.
// So these lines are acting as a fallback.
$medium-screen__min-width: 480px !default;
$large-screen__min-width: 768px !default;
$breakpoint-l: 912px !default;
$breakpoint-xl: 1152 !default;
$breakpoint-xxl: 1440px !default;

$breakpoints: (
  'breakpoint-s': #{$medium-screen__min-width},
  // 480px
  'breakpoint-m': #{$large-screen__min-width},
  // 768px
  'breakpoint-l': #{$breakpoint-l},
  // 912px
  'breakpoint-xl': #{$breakpoint-xl},
  // 1152
  'breakpoint-xxl': #{$breakpoint-xxl},
  // 1440px
) !default;

@mixin responsiveMixin($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
