@import '~@eg/elements/styles/ergo-one/core.css';
@import '~@eg/elements/styles/breakpoints.scss';
@import '~@eg/elements/styles/ergo-one/colors.scss';
@import '~@eg/elements/styles/ergo-one/misc.scss';

:root {
  // Spaces
  --base-spacer--xxs: 0.125rem; // 2px
  --base-spacer--xs: 0.25rem; // 4px
  --base-spacer--s: 0.5rem; // 8px
  --base-spacer--ms: 0.625rem; // 10px
  --base-spacer--m: 0.75rem; //12px
  --base-spacer--ml: 0.875rem; // 14px
  --base-spacer: 1rem; // 16px
  --base-spacer--l: 1.25rem; // 20px
  --base-spacer--xl: 1.5rem; // 24px
  --base-spacer--xxl: 1.75rem; // 28px
  --base-spacer--3xl: 2rem; // 32px
  --base-spacer--3xxl: 3rem; // 48px
  --base-spacer--4xl: 4rem; // 64px
  --base-spacer--5xl: 6rem; // 96px
  --base-spacer--6xl: 8rem; // 128px
  --base-spacer--7xl: 9rem; // 144px
  --base-spacer--8xl: 10rem; // 160px
  --base-spacer--8xxl: 11rem; // 176px
  --base-spacer--9xl: 19.5rem; // 312px

  //Percentages
  --base-percentage--by-one: 1%;
  --base-percentage--by-five: 5%;
  --base-percentage--by-ten: 10%;
  --base-percentage--by-fifty: 50%;
  --base-percentage: 100%;
  --base-percentage--by-hundred-fifty: 150%;
  --base-percentage--by-two-hundred: 200%;

  // Fonts
  --base-font-size: 0.875rem; // 14px
  --base-font-size--small-screen: 0.75rem; //12px
  --base-font-size--medium-screen: 0.875rem; // 14px
  --base-font-size--large-screen: 1rem; // 16px
  --base-font-size--large-screen-l: 1.125rem; // 18px
  --base-line-height--s: 1.25rem; // 20px
  --base-line-height: 1.5rem; // 24px
  --base-line-height--l: 1.75rem; //28px
  --base-line-height--xl: 2rem; // 32px
  --base__heading__font-weight-semibold: 600;
  --base__heading__font-weight-bold: bold;
  --base-font-family: 'FS Me', Arial, Helvetica, sans-serif;
  --base-font-family-heading: 'Fedra Serif', Georgia, 'Times New Roman', serif;

  // Colors
  --color-primary: #{$theme-primary};
  --color-primary-bg: rgba(142, 0, 56, 0.08);
  --color-secondary: #{$theme-secondary};
  --color-tertiary: #{$theme-tertiary};
  --color-brand--lighter: #{$theme-brand-color-lighter};
  --color-brand--light: #{$theme-brand-color-light};
  --color-brand: #{$theme-brand-color};
  --color-brand--dark: #{$theme-brand-color-dark};
  --color-brand--darker: #{$theme-brand-color-darker};
  --color-white: #{$theme-white};
  --color-smooth-gray: #f7f7f7;
  --color-lightest-gray: #{$theme-lightest-gray};
  --color-lighter-gray: #{$theme-lighter-gray};
  --color-light-gray: #{$theme-light-gray};
  --color-medium-gray: #{$theme-medium-gray};
  --color-dark-gray: #{$theme-dark-gray};
  --color-darker-gray: #{$theme-darker-gray};
  --color-darkest-gray: #{$theme-darkest-gray};
  --color-black: #{$theme-black};
  --color-error: #{$theme-error-color};
  --color-success: #{$theme-success-color};
  --color-additional-5-lighter: #{$theme-additional-5-lighter};
  --color-light-blue: #{$theme-additional-3-lighter};
  --color-light-aditional-1-lighter: #{$theme-additional-1-lighter};
  --color-light-green: #{$theme-additional-2-lighter};
  --color-light-red: #{$theme-additional-1-lighter};

  // Border
  --base-border: 1px;
  --base-border--l: calc(var(--base-border) * 1.5);
  --base-border--xl: calc(var(--base-border) * 2);
  --base-border--xxl: calc(var(--base-border) * 2.5);

  // Border Radius
  --base-border-radius--small: #{$base__border-radius--small};
  --base-border-radius--large: #{$base__border-radius--large};
  --base-border-radius--button-mobile: 1.25rem;
  --base-border-radius--button-desktop: 1.5rem;

  // Shadows
  --base-box-shadow--smallest: #{$base__box-shadow--smallest};
  --base-box-shadow--small: #{$base__box-shadow--small};
  --base-box-shadow--medium: #{$base__box-shadow--medium};
  --base-box-shadow--large: #{$base__box-shadow--large};
  --base-box-shadow--largest: #{$base__box-shadow--largest};

  // Heading
  --base-h1-font-size: calc(var(--base-font-size) * 3);
  --base-h2-font-size: calc(var(--base-font-size) * 2);
  --base-h2-v1-font-size: calc(var(--base-font-size) * 2);
  --base-h3-font-size: calc(var(--base-font-size) * 1.75);
  --base-h3-v1-font-size: calc(var(--base-font-size) + 10px);
  --base-h4-font-size: calc(var(--base-font-size) * 1.5);
  --base-h5-v2-font-size: calc(var(--base-font-size--large-screen) * 1.25);
  --base-h5-font-size: calc(var(--base-font-size) * 1.125);
  --base-h6-font-size: var(--base-font-size);

  // Container
  --base-container: calc(38.5rem);

  // Icons
  --base-icon-medium-width: 3rem;
  --base-icon-medium-height: 3rem;

  // LineHeight
  --base-line-height-small-screens: 18px;

  // Fonts
  --base-font: 'FS Me';

  // Height
  --base-height--normal: 1.5rem;

  //Button
  --base-button--width--large: 17rem; //271px
  --base-button--width--medium: 13.25rem; //212px
  --base-button--height--medium: 2.5rem; //40px
}

/**
 * This object will allow us to centralize z-index,
 * so we can have a better understanding of what is
 * on top of what.
 *
 * Commented lines starting with `ergo` in the name
 * are being used as reference.
 */
$zIndex: (
  'background': -1,
  'content': 1,
  'header': 10,
  // 'ergoHeader': 500,
  'loadingSpinner': 1002,
  'modal': 1000000000000,
);
