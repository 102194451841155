@import '../../styles/mixins';
.questions-section {
  &__title {
    font-family: var(--base-font-family-heading);
    padding: var(--base-spacer) var(--base-spacer--xl);
    background-color: var(--color-light-aditional-1-lighter);
    margin-bottom: 0;
    line-height: var(--base-spacer--l);
    font-size: var(--base-font-size--large-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen-l);
    }
  }

  .question-category {
    scroll-margin-top: var(--base-spacer--9xl);
  }

  .additional-question {
    display: flex;
    flex-direction: column;
    padding: var(--base-spacer) var(--base-spacer--s);

    &__header {
      margin: 0;
      font-size: var(--base-font-size--large-screen);

      @include responsiveMixin('breakpoint-m') {
        font-size: var(--base-font-size--large-screen-l);
      }
    }

    &__subtle_paragraph {
      margin: 0;
    }

    &__card {
      margin-top: var(--base-spacer);
      margin-bottom: var(--base-spacer--xl);
      box-shadow: var(--base-box-shadow--medium);
      border-radius: var(--base-border-radius--small);
      padding: var(--base-spacer--xl);
    }

    @include responsiveMixin('breakpoint-m') {
      padding: var(--base-spacer) var(--base-spacer);
    }

    &__select {
      //Overrides EE's select styles in order to comply with design
      margin-bottom: 0 !important;

      .ee_form-row__item {
        margin: 0;

        &-container {
          max-width: var(--base-percentage) !important;
          .ee_select {
            width: var(--base-percentage);
          }
        }
      }

      @include responsiveMixin('breakpoint-m') {
        display: flex;
        align-items: baseline;
        column-gap: var(--base-spacer);
        &::after {
          //Overrides pseudo-element from EE's styles
          content: none !important;
        }
        .ee_form-row__container {
          flex: 1 1;
          .ee_form-row__item-container {
            padding: 0 !important;
          }
        }
        .ee_form-row__descriptor {
          // Overrides EE's styles to make select label comply with design
          display: block !important;
          flex-basis: calc(var(--base-percentage) / 3) !important;
          .ee_form-row__label {
            margin: 0 0 var(--base-spacer) 0 !important;
          }
        }
      }
    }

    &__field {
      &__label {
        margin-top: 0;
      }

      &__input {
        width: calc(var(--base-percentage) - var(--base-spacer--m) * 2);
      }
    }

    .ee_date-input__control {
      .ee_input {
        text-align: center;
      }

      &--month,
      &--day {
        max-width: calc(var(--base-spacer--3xxl) + var(--base-spacer--s));
      }

      &--year {
        max-width: calc(var(--base-spacer--5xl) + var(--base-spacer));
      }
    }
  }

  .additional-question-field {
    margin-bottom: var(--base-spacer);

    .Autocomplete__label,
    .input-text__label--inline,
    .ee_form-row__descriptor {
      padding: 0 0 var(--base-spacer) 0;
      margin: 0;

      @include responsiveMixin('breakpoint-m') {
        flex-basis: calc(var(--base-percentage) / 3);
      }
    }

    .ee_form-row__container,
    .Autocomplete__field,
    .input-wrapper {
      @include responsiveMixin('breakpoint-m') {
        flex: 0 0 calc(var(--base-spacer--ms) * 37);
        flex-flow: inherit;
      }

      .ee_form-row__item {
        margin: 0;
      }
    }

    .RadioGroup.ee_form-row > .ee_form-row__messages .ee_form-row__hint {
      font-size: var(--base-font-size--small-screen);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .input-wrapper {
      max-width: calc(var(--base-spacer--ms) * 37);
    }

    .dropdown {
      &__selectWrapper {
        max-width: calc(var(--base-spacer--ms) * 37);
      }
    }
  }

  .additional-question-radio-group {
    .ee_radio {
      margin-right: var(--base-spacer);
    }
    margin-bottom: var(--base-spacer);
  }

  .errors-for-input {
    margin-top: -var(--base-spacer);
  }

  #previousHouseholdInsuranceDamageAmount,
  #previousGlassInsuranceDamageAmount {
    font-size: var(--base-font-size--large-screen);
  }
}
