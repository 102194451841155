@import '../../styles/mixins';

.information-wrapper {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: stretch;
  justify-items: start;

  @include responsiveMixin('breakpoint-m') {
    grid-template-columns: 1fr 6fr 1fr;
  }

  &__icon {
    margin-top: var(--base-spacer--s);
    align-items: stretch;
    display: grid;
    grid-template-rows: 1fr 1fr;

    @include responsiveMixin('breakpoint-m') {
      justify-self: end;
      margin-right: var(--base-spacer--m);
    }
  }
}

.information-text {
  &__title {
    margin-top: var(--base-spacer--m);
    font-weight: var(--base__heading__font-weight-bold);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
  }

  &__subtitle {
    margin-top: var(--base-spacer--m);
    font-size: var(--base-font-size--small-screen);
  }
}

.information-tooltip__icon {
  justify-self: end;
}
