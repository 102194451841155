@import '../../../../styles/mixins';

.account-details {
  display: flex;
  flex-direction: column;

  @include responsiveMixin('breakpoint-m') {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: var(--base-spacer--5xl);
  }

  .input-text {
    width: 100%;
  }

  .ee_card {
    margin-top: var(--base-spacer--xl);
    @include responsiveMixin('breakpoint-m') {
      margin-top: var(--base-spacer--3xl);
    }
  }

  &-input {
    &__error-message {
      display: flex;
      justify-content: flex-start;
      margin: var(--base-spacer--m) 0;
      color: var(--color-error);
      font-size: var(--base-font-size--small-screen);

      @include responsiveMixin('breakpoint-m') {
        justify-content: center;
        margin-left: var(--base-spacer--7xl);
      }
    }

    @include responsiveMixin('breakpoint-m') {
      display: flex;
      flex-direction: row;
      column-gap: var(--base-spacer--5xl);
      margin-left: var(--base-spacer--5xl);
    }

    &__iban {
      max-width: 350px;
      margin-top: var(--base-spacer--m);
      margin-bottom: 0;

      @include responsiveMixin('breakpoint-m') {
        margin: 0;
        padding: 0;
        width: 100%;
        margin-left: var(--base-spacer--3xxl);
      }
    }
  }
}

.form-section-body__card {
  display: grid;
  max-width: 700px;
  grid-template-columns: 1.5rem 1fr;
  margin: 12px auto 36px auto;

  &-paragraph {
    display: grid;
    grid-template-rows: 1fr 0.5fr;
    row-gap: var(--base-spacer);
    padding: 0 var(--base-spacer--s);
    margin-top: 0px;
    @include responsiveMixin('breakpoint-m') {
      padding: 0;
      font-size: var(--base-font-size--medium-screen);
      margin-left: var(--base-spacer);
    }
  }

  &-icon {
    width: 1.5rem;

    @include responsiveMixin('breakpoint-m') {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

//ERGO STYLES

.input-title {
  @include responsiveMixin('breakpoint-m') {
    padding-top: 0;
    display: flex;
    align-items: center;
    width: var(--base-spacer--5xl);
  }
}
