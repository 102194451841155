@import '../../../../styles/mixins';

.form-section-body {
  margin: var(--base-spacer) 0;
  @include responsiveMixin('breakpoint-m') {
    margin: var(--base-spacer--xl) 0;
  }

  padding: 0 var(--base-spacer--xl);
  &--small-padding {
    padding: 0 var(--base-spacer--m);
  }
}
