@import '../../styles/mixins';

.input-text {
  &--inline {
    overflow: hidden;
    overflow-wrap: break-word;
    gap: var(--base-spacer);

    @include responsiveMixin('breakpoint-m') {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      gap: var(--base-spacer);
    }
  }

  &--col {
    overflow: hidden;
    overflow-wrap: break-word;
    display: block;
  }

  &__label {
    &--col {
      align-items: center;
      display: flex;
      margin-bottom: var(--base-spacer);
      width: 100%;
    }

    &--inline {
      display: block;
      margin: var(--base-spacer--l) 0;
      @include responsiveMixin('breakpoint-m') {
        flex-basis: calc(100% / 3);
      }
    }
  }

  &__input {
    &--col,
    &--inline {
      color: var(--color-darker-gray);
      background: var(--color-smooth-gray);
      border-color: var(--color-dark-gray);
      outline: none;
      border-width: 0 0 0.075rem;
      border-radius: var(--base-spacer--xs) var(--base-spacer--xs) 0 0;
      padding: var(--base-spacer--m);

      &:focus,
      &:active {
        border-color: var(--color-brand--light);
        color: var(--color-darkest-gray);
        box-shadow: inset 0 calc(0.95rem - var(--base-spacer)) 0
          var(--color-brand--light);
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        transition-property: border-color, box-shadow, background, color;
      }
    }
  }

  .input-wrapper {
    flex: 1;

    .input-text__input--inline,
    .input-text__input--col {
      width: 100%;
      box-sizing: border-box;
      height: calc(var(--base-spacer--ms) * 4.9);
      font-size: var(--base-font-size--medium-screen);
      @include responsiveMixin('breakpoint-m') {
        font-size: var(--base-font-size--large-screen);
      }
      appearance: textfield;
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    &__error-message {
      display: block;
      margin: var(--base-spacer--m) 0;
      color: var(--color-error);
      font-size: var(--base-font-size--small-screen);
    }
  }

  .is-error {
    &__input {
      color: var(--color-error);
      border-color: var(--color-error);
      border-radius: 0%;

      &:focus,
      &:active {
        box-shadow: inset 0 calc(0.95rem - var(--base-spacer)) 0
          var(--color-error);
      }
    }
  }
}
