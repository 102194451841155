.LoadingIndicator {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;

  .ee_loading-spinner {
    background: transparent;
    position: relative;
  }
}
