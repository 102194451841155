@import '../../styles/mixins';

.modal-list {
  line-height: var(--base-line-height--xl);
}

//The only way that I found to override their own style. Used an ID, not to use important.
#override-padding {
  padding-left: var(--base-spacer);
}

.footer__text {
  padding-top: calc(var(--base-spacer) * 3);
  text-align: center;
  line-height: var(--base-line-height-small-screens);
  font-size: var(--base-font-size--medium-screen);

  + button.button-reset.esc_text-link {
    font-size: var(--base-font-size--medium-screen);
  }
}

.modal {
  &-title {
    font-size: var(--base-h3-font-size);
    line-height: var(--base-line-height--xl);
  }
  &-info {
    margin-top: var(--base-spacer--3xl);
    @include responsiveMixin('breakpoint-m') {
      margin-top: var(--base-spacer--4xl);
    }

    &-title {
      font-size: var(--base-font-size--medium-screen);
      @include responsiveMixin('breakpoint-m') {
        font-size: var(--base-line-height--s);
      }
    }
    &-button {
      margin-top: 24px;
      .ee_button__content {
        font-size: var(--base-font-size--medium-screen);
        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size--large-screen);
        }
      }
    }
    .bullet-list {
      padding-bottom: 10px;
      @include responsiveMixin('breakpoint-s') {
        padding-bottom: 0px;
      }
      > li::marker {
        font-size: var(--base-line-height--s);
      }
      > li {
        font-size: var(--base-font-size--medium-screen);
        line-height: var(--base-spacer--xl);
        height: var(--base-spacer--xl);
        letter-spacing: 0;
        margin: var(--base-spacer--m) 0;
        left: -15px;
        position: relative;
        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size--large-screen);
        }
      }
    }
  }
}

.living-place-options {
  .esc_col {
    border-width: var(--base-spacer--m) 0;
  }
  @include responsiveMixin('breakpoint-m') {
    .esc_col {
      border-width: var(--base-spacer--m);
    }
  }
}
