@import '../../styles/mixins';

.bank-account-details__container {
  display: grid;

  @include responsiveMixin('breakpoint-m') {
    display: flex;
    flex-direction: column;
    margin-top: var(--base-spacer--s);
  }

  .input-wrapper {
    .input-text__input--inline {
      box-sizing: border-box;
      height: calc(var(--base-spacer--ms) * 4.9);
    }
  }
}
