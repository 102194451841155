@import '../../../../../styles/mixins';

.textfield-container {
  @include responsiveMixin('breakpoint-m') {
    width: 90%;
    display: flex;
    flex-direction: row;
    column-gap: calc(var(--base-percentage--by-ten) * 2 + var(--base-spacer));
    align-items: center;
    margin-bottom: var(--base-spacer--xl);
  }

  &__input {
    @include responsiveMixin('breakpoint-m') {
      width: var(--base-percentage--by-two-hundred);
    }
  }
}

.select-row-container {
  @include responsiveMixin('breakpoint-m') {
    margin-bottom: var(--base-spacer--xs);
  }
}

#previousHouseholdInsuranceDamageAmount {
  //Used to override.
  &::placeholder {
    text-align: left;
  }
}
