.horizontal-list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  gap: var(--base-spacer);

  &__li {
    flex: 1;
  }
}
