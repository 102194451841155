@import '../../../../styles/mixins';
@import '../../PersonalDataFields.scss';

.personal-data-input {
  @include responsiveMixin('breakpoint-m') {
    align-items: center;
    width: calc(var(--base-percentage--by-ten) * 2);
    margin: 0 var(--base-spacer--m) 0 0;
  }

  &-title {
    @include titleElement();
  }

  &__name-container {
    @include fieldElement();
  }

  &__title {
    @include titleElement();
  }

  &__firstname {
    scroll-margin-top: var(--base-spacer--9xl);
    @include inputHalfField();

    @include responsiveMixin('breakpoint-m') {
      margin-right: var(--base-spacer--m);
    }
  }

  &__lastname {
    scroll-margin-top: var(--base-spacer--9xl);
    @include inputHalfField();
    margin: var(--base-spacer--m) 0 0 0;

    @include responsiveMixin('breakpoint-m') {
      margin: 0;
    }
  }

  &__content {
    width: var(--base-percentage);

    @include responsiveMixin('breakpoint-m') {
      width: calc(var(--base-percentage--by-ten) * 7);
    }

    &__name {
      display: flex;
      flex-direction: column;

      @include responsiveMixin('breakpoint-m') {
        flex-direction: row;
      }
    }
  }

  &__birthdate {
    @include fieldElement();
  }

  &__address {
    @include fieldElement();
  }

  &__checkbox {
    scroll-margin-top: var(--base-spacer--9xl);
    @include fieldElement();

    .ee_checkbox {
      @include inputFullElement();
    }
  }

  &__text {
    font-size: var(--base-font-size--medium-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
    margin: var(--base-spacer--s) 0;
  }

  &__container-error {
    @include inputError();
  }

  &--error {
    @include inputError();
  }
}
