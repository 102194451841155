@import '../../../../styles/mixins';

.form-section-header {
  box-sizing: border-box;
  color: var(--color-primary);
  font-family: var(--base-font-family-heading);
  margin-bottom: var(--base-spacer--3xl);
  padding: 0 var(--base-spacer--xl);
  &--small-padding {
    padding: 0 var(--base-spacer--m);
  }
  max-width: 100%;
  overflow-x: hidden;
  text-align: center;

  @include responsiveMixin('breakpoint-l') {
    width: calc(var(--base-spacer--5xl) * 10);
  }

  &--with-price {
    text-align: center;
    color: var(--color-primary);
    font-family: var(--base-font-family-heading);
    font-size: var(--base-font-size--large-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: calc(var(--base-font-size--large-screen) * 1.2);
    }
  }

  &__title,
  &__subtitle {
    hyphens: none;
  }

  &__title {
    font-weight: 600;
    font-size: var(--base-line-height--s);
    line-height: var(--base-line-height--l);
    margin-top: 0;
    padding-top: var(--base-spacer--3xl);
    vertical-align: baseline;

    @include responsiveMixin('breakpoint-s') {
      padding-top: var(--base-spacer--3xl);
    }

    @include responsiveMixin('breakpoint-m') {
      padding-top: var(--base-spacer--3xxl);
      font-size: var(--base-h2-font-size);
    }
  }

  &__subtitle {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    font-family: var(--base-font-family);
    margin: 0;
    color: var(--color-darkest-gray);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
  }
}
