@import '../../styles/mixins';

.feedback-message {
  text-align: center;
  margin: var(--base-spacer--3xl) 0 var(--base-spacer--3xl);
  line-height: var(--base-spacer--l);

  @include responsiveMixin('breakpoint-m') {
    margin-bottom: var(--base-spacer--4xl);
    font-size: var(--base-font-size--large-screen);
    line-height: var(--base-spacer--l);
  }
}
