@import '../../../../styles/mixins';

.footer-notes {
  display: flex;
  flex-direction: column;
  line-height: var(--base-spacer--l);
  margin-top: var(--base-spacer--2xl);

  &__authorization-text {
    margin-top: var(--base-spacer--3xl);
    font-weight: var(--base__heading__font-weight-semibold);

    @include responsiveMixin('breakpoint-m') {
      margin-top: var(--base-spacer--xl);
    }
  }

  &__notice-title-text,
  &__notice-text {
    margin-bottom: 0;
    font-size: var(--base-font-size--small-screen);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--medium-screen);
    }
  }

  &__direct-debit-box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-weight: 600;

    @include responsiveMixin('breakpoint-m') {
      margin-top: var(--base-spacer--xl);
      font-weight: 600;
    }
  }
}
