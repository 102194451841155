@import '../../styles/mixins';

.feedback-header {
  position: relative;
  border-bottom: none;
  background: transparent;
  box-shadow: none;
  text-align: center;
  padding: var(--base-spacer--4xl) var(--base-spacer--xl);
  background-color: var(--color-light-green);

  &__title {
    color: var(--color-primary);
    font-family: var(--base-font-family-heading);
  }
  &__subtitle {
    letter-spacing: 0;
    font-weight: var(--base__heading__font-weight-bold);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
  }
}
