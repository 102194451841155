@import '../../../../styles/mixins';

.zip-code {
  display: flex;
  margin-bottom: var(--base-spacer);

  @include responsiveMixin('breakpoint-m') {
    margin-bottom: var(--base-spacer);
    justify-content: flex-start;
    column-gap: var(--base-spacer);
  }

  &__zip-number {
    flex: 1;
    margin: 0 var(--base-spacer--m) 0 0;

    @include responsiveMixin('breakpoint-m') {
      margin: 0;
    }
  }

  &__zip-city {
    display: flex;
    flex: 2;
    width: 100%;
  }

  .input-text {
    &__label {
      &--col,
      &--inline {
        margin-bottom: var(--base-spacer);

        .input-title {
          width: 100%;
        }
      }
    }

    &__input {
      &--col,
      &--inline {
        width: 100%;
      }
    }
  }
}
