@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/index.scss';

[data-theme='ergo-one'] {
  .app-body-spacing {
    // Max width in invision + inner padding left + inner padding right
    max-width: calc(864px + calc(var(--base-spacer--xl) * 2));
    margin: 0 auto;
    overflow-x: hidden;

    & > .esc_container {
      max-width: 100%;
      overflow-x: hidden;
    }
  }

  .ee_modal {
    z-index: map-get($zIndex, 'modal') !important;
  }

  .ee_form-footer {
    margin-left: var(--base-spacer--xl);
    margin-right: var(--base-spacer--xl);

    @include responsiveMixin('breakpoint-m') {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
