@import '../../../styles/mixins';

.AemModal {
  .ee_modal__container.ee_modal__container--extended-width {
    max-width: 52em;
  }

  .ee_modal {
    &__content {
      padding: 0;
      min-height: 100px;
    }
  }

  &--iframe .ee_modal__content {
    padding: 10px 0;
    height: 500px;
    overflow-y: hidden;

    @include responsiveMixin('breakpoint-m') {
      height: 800px;
    }

    @include responsiveMixin('breakpoint-l') {
      height: 800px;
    }
  }

  &--iframe .ee_modal__main {
    width: 350px;

    @media (min-width: 650px) {
      width: 600px;
    }

    @include responsiveMixin('breakpoint-m') {
      width: 700px;
    }

    @include responsiveMixin('breakpoint-l') {
      width: 950px;
    }
  }
}
