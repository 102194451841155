@import '../../../../styles/mixins';

.street-info {
  margin-bottom: var(--base-spacer);

  @include responsiveMixin('breakpoint-m') {
    margin-bottom: var(--base-spacer);
    display: flex;
    justify-content: flex-start;
    column-gap: var(--base-spacer);

    &__name {
      flex: 2;
    }
  }

  &__number {
    margin: var(--base-spacer--m) 0 0 0;

    @include responsiveMixin('breakpoint-m') {
      flex: 1;
      margin: 0;
    }
  }
}
