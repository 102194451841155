@mixin fieldElement {
  display: flex;
  flex-flow: column;
  margin-top: var(--base-spacer--ml);

  @include responsiveMixin('breakpoint-m') {
    flex-flow: row;
    justify-content: space-between;
  }
}

@mixin titleElement {
  display: flex;
  width: var(--base-percentage);
  margin: 0 0 var(--base-spacer--m) 0 !important;
  font-weight: normal;
  font-size: var(--base-font-size--medium-screen);

  @include responsiveMixin('breakpoint-m') {
    max-height: calc(var(--base-spacer--ms) * 4.8);
    align-items: center;
    font-size: var(--base-font-size--large-screen);
    width: calc(var(--base-percentage--by-ten) * 2.5);
    margin: 0 var(--base-spacer--m) 0 0 !important;
  }
}

@mixin inputFullElement {
  width: var(--base-percentage);
}

@mixin inputElement {
  @include inputFullElement();

  @include responsiveMixin('breakpoint-m') {
    width: calc(var(--base-percentage--by-ten) * 7);
  }
}

@mixin inputHalfField {
  display: flex;
  flex-direction: column;

  @include responsiveMixin('breakpoint-m') {
    margin-bottom: 0;
    width: var(--base-percentage--by-fifty);

    .ee_input-wrapper {
      margin: 0;
      justify-content: flex-end;
    }
  }
}

@mixin inputError {
  color: var(--color-error);
  font-size: var(--base-font-size--small-screen);
  margin: var(--base-spacer--ms) 0 0 0;

  @include responsiveMixin('breakpoint-m') {
    font-size: var(--base-font-size--medium-screen);
  }

  &--fix {
    color: var(--color-error);
    font-size: var(--base-font-size--small-screen);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--medium-screen);
    }
  }
}
