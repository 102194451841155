@import '../../styles/mixins';
.checkbox__content {
  align-items: center;
  .ee_checkbox__label {
    font-size: var(--base-font-size--medium-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
  }
  @include responsiveMixin('breakpoint-m') {
    width: calc(var(--base-percentage--by-ten) * 7);
  }

  &-error {
    color: var(--color-error);
    font-size: var(--base-font-size--small-screen);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--medium-screen);
    }
  }
}
