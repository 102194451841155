.download-link {
  &--disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;

    :hover {
      cursor: default;
      text-decoration: none !important;
    }
  }
}
