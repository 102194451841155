@import '../../styles/mixins';

[data-theme='ergo-one'] {
  .app-body-spacing {
    .checkboxRow {
      column-gap: var(--base-spacer);
      display: grid;
      grid-template-columns: 30px 5fr 40px;
      padding-bottom: var(--base-spacer);
      padding-top: var(--base-spacer);

      @include responsiveMixin('breakpoint-m') {
        column-gap: 0;
        grid-template-columns: 60px 10fr 40px;
      }

      &__field,
      &__tooltip {
        align-items: center;
        display: flex;
      }

      &__field {
        justify-content: flex-start;

        &--disabled {
          padding-left: 2px;
        }
      }

      &__square {
        border: 1px solid var(--color-medium-gray);
        height: 22px;
        margin-left: -2px;
        width: 22px;
      }

      &__label {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: var(--base-spacer--xs);

        @include responsiveMixin('breakpoint-m') {
          row-gap: var(--base-spacer--s);
        }

        &--disabled {
          color: var(--color-medium-gray);
        }
      }

      &__labelTitle,
      &__labelSubtitle {
        align-items: center;
        display: flex;
      }

      &__labelSubtitle {
        font-size: var(--base-font-size--small-screen);
        display: flex;
        flex-flow: row nowrap;

        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size);
        }
      }

      &__tooltip {
        justify-content: flex-end;
      }
    }
  }
}
