@import '../../styles/mixins';

$border-radius--small: var(--base-border-radius--small);
$border-radius--large: var(--base-border-radius--large);
$box-padding--small: var(--base-spacer--l) var(--base-spacer--l);
$box-padding--large: var(--base-spacer--3xl) var(--base-spacer--3xxl);

.consultation-note {
  border: var(--base-border) solid var(--color-dark-gray);
  border-radius: $border-radius--small;
  margin-bottom: var(--base-spacer--3xl);
  @include responsiveMixin('breakpoint-m') {
    border-radius: $border-radius--large;
    margin-bottom: var(--base-spacer--3xxl);
  }

  &__title {
    margin-top: 0;
    font-size: var(--base-font-size--large-screen);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen-l);
    }
  }

  &__info {
    padding: $box-padding--small;
    @include responsiveMixin('breakpoint-m') {
      padding: $box-padding--large;
    }
  }

  &__checkbox {
    border-bottom-left-radius: $border-radius--small;
    border-bottom-right-radius: $border-radius--small;
    padding: $box-padding--small;
    background-color: var(--color-light-aditional-1-lighter);
    @include responsiveMixin('breakpoint-m') {
      border-bottom-left-radius: $border-radius--large;
      border-bottom-right-radius: $border-radius--large;
      padding: $box-padding--large;
    }
    .ee_checkbox__label {
      font-size: var(--base-font-size--medium-screen);
      @include responsiveMixin('breakpoint-m') {
        font-size: var(--base-font-size--large-screen);
      }
    }
  }
}

.step-section {
  display: flex;
  flex-direction: column;
  text-align: center;

  margin-bottom: var(--base-spacer--3xl);
  @include responsiveMixin('breakpoint-m') {
    margin-bottom: var(--base-spacer--4xl);
  }

  &__title {
    margin-top: 0;
    font-size: var(--base-font-size--large-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen-l);
    }
  }
  &__subtitle {
    margin-top: 0;
    font-size: var(--base-font-size--medium-screen);
    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen);
    }
  }
  &__action {
    margin-top: var(--base-spacer);
    display: flex;
    justify-content: center;
  }
  &__error {
    color: var(--color-error);
    margin: var(--base-spacer--xs);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
