@import '../../../../styles/mixins';
.success {
  --icon-size: 20px;
  --card-inner-padding: 10px;
  max-width: 420px;
  margin: 0 auto;

  &__card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @include responsiveMixin('breakpoint-s') {
      // Overrides EE's tooltip's styles in order to comply with desktop design
      span.ee_tooltip-icon {
        padding-bottom: 0 !important;
      }
    }
  }

  &__icon {
    &_ok {
      fill: var(--color-success);
      min-width: var(--icon-size);
      min-height: var(--icon-size);
      padding: var(--card-inner-padding);
    }
  }

  &__message {
    font-size: var(--base-font-size);
    text-align: left;
    padding: var(--card-inner-padding);
    .Price {
      [data-part='currency'] {
        padding-left: var(--base-spacer--xs);
      }
    }
  }

  .ee_tooltip-icon {
    button {
      width: var(--base-spacer--l) !important;
      height: var(--base-spacer--l) !important;
      padding: 0 !important;
    }
  }
}
