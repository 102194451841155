@import '../../../../../../styles/mixins';

[data-theme='ergo-one'] {
  .app-body-spacing {
    .insurance-date-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__input {
        max-width: 256px;
        margin: 0 auto;
      }

      @include responsiveMixin('breakpoint-m') {
        &__input {
          width: 40%;
        }
      }

      .ee_control-with-hint {
        width: 100%;

        .ee_date-input__control {
          .ee_input {
            text-align: center;
          }

          &--month,
          &--day {
            max-width: calc(var(--base-spacer--3xxl) + var(--base-spacer--s));
          }

          &--year {
            max-width: calc(var(--base-spacer--5xl) + var(--base-spacer));
          }
        }

        &__hint {
          margin-top: var(--base-spacer--m);
          text-align: center;
          padding-left: 0;
        }
      }

      .ee_input {
        text-align: center !important;
        text-indent: 0 !important;
      }
    }
  }
}
