@import '../../../../styles/mixins';
@import '../../PersonalDataFields.scss';

.contact-information {
  &-email {
    @include fieldElement();

    &__addresse {
      @include titleElement();

      .ee_tooltip-icon__icon {
        color: black !important;
      }
    }
    &__input-container {
      @include inputElement();
      scroll-margin-top: var(--base-spacer--9xl);
    }
  }
  &-telephone {
    &__texts {
      &-title {
        font-size: var(--base-font-size--small-screen);
        font-weight: bold;
        @include inputElement();

        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size--medium-screen);
        }
      }
    }
  }
}
