.Autocomplete {
  width: 100%;

  &__label {
    &--col,
    &--inline {
      align-items: center;
      display: flex;
      width: 100%;
      flex-basis: calc(var(--base-percentage) / 3);
    }

    &--col {
      margin-bottom: var(--base-spacer);
    }
  }

  &__field {
    flex: 1 1;
    &--col,
    &--inline {
      width: 100%;
    }
  }

  &__errors {
    color: var(--color-error);
    display: block;
    font-size: var(--base-font-size--small-screen);
    margin-top: var(--base-spacer--m);
  }

  &--error {
    .ee_input-wrapper .ee_input {
      &,
      &:focus {
        border-color: var(--color-error);
        box-shadow: inset 0 -0.5px 0 0 var(--color-error);
      }
    }
  }
}
