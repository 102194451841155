@import '../../styles/mixins';
@import './PersonalDataFields.scss';

.personal-data {
  .ee_select,
  .input-wrapper {
    max-width: calc(var(--base-spacer--ms) * 37);
  }

  .gender-container {
    scroll-margin-top: var(--base-spacer--9xl);
    .ee_radio-group-row {
      @include inputFullElement();
      flex-flow: row;
      justify-content: space-between;

      .ee_form-row__descriptor {
        @include titleElement();
      }
      .ee_form-row__container {
        @include inputElement();
      }
    }

    &--radioSalutation {
      .ee_radio__label {
        font-size: var(--base-font-size--medium-screen);
        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size--large-screen);
        }
      }
    }

    .ee_form-row::after {
      display: none;
    }

    @include responsiveMixin('breakpoint-m') {
      .ee_radio-group-row,
      .ee_form-row,
      .ee_form-row__item,
      .ee_form-row--wide {
        margin-bottom: 0 !important; //Only way to override E. Element.
      }
    }
  }

  &--header {
    font-size: var(--base-font-size--large-screen);
    margin-bottom: var(--base-line-height);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-font-size--large-screen-l);
      margin: var(--base-spacer--3xxl) 0;
    }
  }

  .input-wrapper {
    .input-text__input--inline {
      box-sizing: border-box;
      height: calc(var(--base-spacer--ms) * 4.9);
    }
  }
}
