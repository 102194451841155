.progressbar-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

progress {
  -webkit-appearance: none;
  height: 4px;
}

progress[value] {
  width: 100%;
  border-radius: 0 2px 2px 0;
}

progress[value]::-webkit-progress-bar {
  background-color: #fff;
  border-top: 1px solid var(--color-light-gray);
}

progress[value]::-webkit-progress-value {
  border-radius: 0 2px 2px 0;
  background-color: var(--color-primary);
}

progress::-moz-progress-bar {
  border-radius: 0 2px 2px 0;
  background-color: var(--color-primary);
}
