@import '../../../../styles/mixins';

.form-action-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  .next-button {
    @include responsiveMixin('breakpoint-m') {
      width: var(--base-button--width--large);
    }
  }
}
