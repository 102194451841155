.error {
  margin: 0 auto;
  padding: 0.5rem 0;
  width: fit-content;
  text-align: center;
  overflow: hidden;

  &__message {
    color: var(--color-error);
    font-size: var(--base-font-size);
  }
}
