@import '../../../../styles/mixins';
.account-owner {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--base-spacer--xl);

  @include responsiveMixin('breakpoint-m') {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: var(--base-spacer--5xl);
    margin-left: var(--base-spacer--5xl);
  }

  &__description {
    margin-bottom: var(--base-spacer--m);

    @include responsiveMixin('breakpoint-m') {
      margin: 0;
      padding: 0;
    }
  }

  &__name {
    font-size: var(--base-font-size--large-screen);

    @include responsiveMixin('breakpoint-m') {
      padding-top: 0;
      margin-left: var(--base-spacer--xxl);
    }
    &--error {
      color: var(--color-error);
      margin-left: var(--base-spacer--xxl);
    }
  }
}
