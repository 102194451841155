@import '../../styles/mixins';
@import '../../pages/step9/PersonalDataFields.scss';

.contact-information {
  &-telephone {
    @include fieldElement();

    &__title {
      @include titleElement();
      margin: var(--base-spacer--l) 0 var(--base-spacer--l) 0 !important;

      @include responsiveMixin('breakpoint-m') {
        align-items: start;
      }
    }

    &__inputs {
      &--error {
        @include inputError();
      }
    }

    &__prefixNumber {
      @include inputHalfField();
      .ee_select__control {
        font-size: inherit !important;
        height: calc(var(--base-spacer--ms) * 4.9);
      }

      .ee_form-row {
        margin-bottom: 0 !important;
      }

      .ee_form-row__descriptor {
        padding: 0 !important;
      }
    }

    &__phoneNumber {
      @include inputHalfField();

      @include responsiveMixin('breakpoint-m') {
        margin: 0;
      }
    }

    &__content {
      @include responsiveMixin('breakpoint-m') {
        width: calc(var(--base-percentage--by-ten) * 7);
      }

      &__name {
        display: flex;
        flex-direction: column;

        @include responsiveMixin('breakpoint-m') {
          flex-direction: row;
        }
      }
    }

    &__texts {
      @include fieldElement();

      &__title {
        @include titleElement();
      }

      &__content {
        font-size: var(--base-font-size--small-screen);
        @include inputElement();

        @include responsiveMixin('breakpoint-m') {
          font-size: var(--base-font-size--medium-screen);
        }
      }
    }
  }
}
