@import '../../../../styles/mixins';

.feedback {
  text-align: center;
  font-size: var(--base-h5-font-size);

  &__title {
    margin-bottom: var(--base-spacer--xl);
    font-size: var(--base-h5-v2-font-size);

    @include responsiveMixin('breakpoint-m') {
      font-size: var(--base-h2-v1-font-size);
    }
  }

  &-list {
    padding: 0;
    list-style-type: none;
    text-align: left;
    margin: var(--base-spacer--m) var(--base-spacer);
    font-size: var(--base-font-size);

    &__element {
      display: flex;
      flex-flow: column wrap;
      row-gap: var(--base-spacer);
      margin-bottom: var(--base-spacer--xl);

      @include responsiveMixin('breakpoint-m') {
        font-size: var(--base-font-size--large-screen);
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
    &__term {
      font-weight: var(--base__heading__font-weight-semibold);
    }
    &__description {
      margin-left: 0;
    }
  }
}
