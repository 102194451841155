@import '../../styles/mixins';

[data-theme='ergo-one'] {
  .app-body-spacing {
    .dropdown {
      width: 100%;

      &__label {
        display: inline-block;
        margin-bottom: var(--base-spacer);
      }

      &__input {
        &.ee_input-wrapper {
          margin: 0;
          .ee_input {
            padding: 0;
          }
        }
      }

      &__select {
        .ee_select__control {
          // avoid showing text under caret icon when select width is small
          padding-right: var(--base-spacer--3xl);
        }
      }

      .ee_select__control {
        &::after,
        &::before {
          display: inline-block;
          content: '';
        }
      }

      &__errors {
        color: var(--color-error);
        font-size: var(--base-font-size--small-screen);
        margin-top: var(--base-spacer--m);
        list-style: none;
        padding-left: 0;
      }

      &.dropdown--inline {
        display: flex;
        flex-direction: column;
        padding: var(--base-spacer) 0;

        @include responsiveMixin('breakpoint-m') {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--base-spacer);
        }

        .dropdown__labelWrapper,
        .dropdown__selectWrapper {
          display: flex;
        }

        .dropdown__labelWrapper {
          flex-basis: calc(var(--base-percentage) / 3);
        }

        .dropdown__selectWrapper {
          flex: 1;
          flex-direction: column;
        }

        .dropdown__errorsWrapper {
          flex-basis: 100%;
          min-width: 0;
        }
      }
    }
  }
}
