@import '../../styles/mixins';

[data-theme='ergo-one'] .RadioGroup {
  display: flex;
  flex-wrap: wrap !important;
  column-gap: var(--base-spacer);
  margin: 0;

  &.ee_form-row > .ee_form-row__messages {
    flex-basis: 100%;
    margin-top: 0;
    width: 100%;
    .ee_form-row__hint {
      padding-left: 0px;
      font-size: var(--base-font-size--small-screen);
      @include responsiveMixin('breakpoint-m') {
        padding-left: var(--base-spacer--ms);
        font-size: var(--base-font-size--medium-screen);
      }
    }
  }
}
