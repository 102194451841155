.address-modal {
  &__description {
    margin-bottom: var(--base-spacer--xs);
  }
}

.button-wrapper {
  margin-top: var(--base-spacer--4xl);
  display: flex;
  justify-content: space-between;

  .ee_button {
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;

      .ee_svg-icon {
        margin-right: var(--base-spacer--ms);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    display: block;
    margin-top: var(--base-spacer--xl);

    &__confirmation {
      margin: var(--base-spacer) 0;
      width: 100%;
    }

    &__contact {
      width: 100%;
    }

    .ee_button {
      &__content {
        font-size: var(--base-font-size);
      }
    }
  }
}
