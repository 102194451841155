@import '../../../../styles/mixins';
.inputTitle {
  display: block;
  height: 1.125rem;
  color: var(--color-light-gray);
  font-family: var(--base-font);
  font-size: var(--base-font-size--medium-screen);
  letter-spacing: 0.015rem;
  line-height: 1.125rem;

  @include responsiveMixin('breakpoint-m') {
    height: 1.25rem;
    line-height: 1.25rem;
  }
}
